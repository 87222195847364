import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <span>&copy; 2021 Sean Oyler</span>
    </div>
  );
};

export default Footer;
